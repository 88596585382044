
import React from "react"
import { Link } from "gatsby"
import parse from "html-react-parser"
import Image from "gatsby-image"
import { Badge, Row, Card } from 'react-bootstrap'

const Sidebar = ({ isSinglePage, posts }) => {
    return (
        isSinglePage ? (
            <Row className="row">
                {
                    posts.edges.map((post, key) => (
                        <div className="col-lg-12 col-md-6" key={post.node.id}>
                            <Card className="border-0">
                                <Card.Body className="px-0 pt-0 pb-3">
                                    <div><small>{post.node.date}</small></div>
                                    <h6><Link to={`/${post.node.slug}/`} itemProp="url">{parse(post.node.title)}</Link></h6>

                                    {post.node.categories.nodes !== null ? post.node.categories.nodes.map((category, index) => (
                                        <Link key={index} to={`/category/${category.slug}/`}><span className="badge badge-secondary mr-3">{category.name}</span></Link>
                                    )) : ""}
                                </Card.Body>
                            </Card>
                        </div>
                    ))
                }
            </Row>
        ) : (
            posts.map(post => {
                const title = post.title
                const featuredImage = {
                    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
                    alt: post.featuredImage?.node?.alt || ``,
                }
                return (
                    <div className="col-md-6 col-lg-12" key={post.uri}>
                    <Card className="border-0" >
                        {/* if we have a featured image for this post let's display it */}
                        {featuredImage?.fluid && (
                            <Image className="sideimg"
                                fluid={featuredImage.fluid}
                                alt={featuredImage.alt}
                                style={{ marginBottom: 0 }}
                            />
                        )}
                        <Card.Body className="px-0">
                            <div><small>{post.date}</small></div>
                            <h6>
                                <Link to={post.uri} itemProp="url">
                                    <span itemProp="headline">{parse(title)}</span>
                                </Link>
                            </h6>
                            
                            {post.categories.nodes !== null ? post.categories.nodes.map((category, index) => (
                                <Link key={index} to={`/category/${category.slug}/`}><Badge pill variant="secondary" >{category.name}</Badge></Link>
                            )) : ""}
                        </Card.Body>
                    </Card>
                    </div>
                )
            })
        )
    )
}

export default Sidebar